<template>
    <section class="enquiry py-4"
             id="enquiry">
        <div class="container py-5">
            <h3 class="text-center title font-weight-bold mb-5 pb-3"
                data-aos="fade-up"
                data-aos-duration="1000">
                <slot></slot>
            </h3>

            <b-form v-on:submit.prevent.stop="submit">
                <div class="row"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <b-form-group class="col-md-9"
                                  label="姓名 (須與身份証明文件上相同)"
                                  label-for="name"
                                  v-bind:valid-feedback="validFeedback"
                                  v-bind:invalid-feedback="invalidNameFeedback"
                                  v-bind:state="isNameValid">
                        <b-form-input class="rounded-0"
                                      id="name"
                                      type="text"
                                      required
                                      v-bind:state="isNameValid"
                                      v-model.trim="name"
                                      v-on:blur.native="validateName">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group class="col-md-3"
                                  label="性別">
                        <b-form-radio-group class="d-flex"
                                            buttons
                                            button-variant="outline-light"
                                            v-model="gender">
                            <b-form-radio class="col rounded-0"
                                          value="male">
                                男
                            </b-form-radio>
                            <b-form-radio class="col rounded-0"
                                          value="female">
                                女
                            </b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="row"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <b-form-group class="col-md-7"
                                  label="電郵"
                                  label-for="email"
                                  v-bind:valid-feedback="validFeedback"
                                  v-bind:invalid-feedback="invalidEmailFeedback"
                                  v-bind:state="isEmailValid">
                        <b-form-input class="rounded-0"
                                      id="email"
                                      type="email"
                                      required
                                      v-bind:state="isEmailValid"
                                      v-model.trim="email"
                                      v-on:blur.native="validateEmail">
                        </b-form-input>
                    </b-form-group>

                    <b-form-group class="col-md-5"
                                  label="聯絡電話"
                                  label-for="phone"
                                  v-bind:valid-feedback="validFeedback"
                                  v-bind:invalid-feedback="invalidPhoneFeedback"
                                  v-bind:state="isPhoneValid">
                        <b-input-group>
                            <b-input-group-text slot="prepend"
                                                class="rounded-0">
                                +852
                            </b-input-group-text>
                            <b-form-input class="rounded-0"
                                          id="phone"
                                          type="tel"
                                          required
                                          minlength="8"
                                          maxlength="8"
                                          pattern="[0-9]{8}"
                                          v-bind:state="isPhoneValid"
                                          v-model.trim="phone"
                                          v-on:blur.native="validatePhone">
                            </b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>

                <div class="mt-2 mb-4"
                     v-if="withBookingOption"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <b-form-checkbox disabled
                                     v-model="bookingAFreeSession">
                        預約免費咨詢
                    </b-form-checkbox>
                </div>

                <div class="row"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     v-show="withBookingOption ? bookingAFreeSession: true">
                    <b-form-group class="col-md-12"
                                  label="預約分店"
                                  label-for="branch"
                                  v-bind:valid-feedback="validFeedback"
                                  v-bind:invalid-feedback="invalidBranchFeedback"
                                  v-bind:state="isBranchValid">
                        <b-form-select class="rounded-0"
                                       required
                                       v-model="branch"
                                       v-on:change="onBranchChange">
                            <option v-bind:value="null">請選擇預約分店</option>
                            <option v-for="salon in treatment.availableBranches"
                                    v-bind:key="salon._id"
                                    v-bind:value="salon">
                                {{ salon.address["zh-hk"] }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="row mt-4"
                     data-aos="fade-up"
                     data-aos-duration="1000"
                     v-show="bookingAFreeSession">
                    <b-form-group class="col-md-6"
                                  label="預約日期"
                                  label-for="preferredDate">
                        <datepicker id="preferredDate"
                                    ref="preferredDatePicker"
                                    bootstrap-styling
                                    input-class="rounded-0 bg-white"
                                    placeholder="請選擇閣下喜歡的日期"
                                    v-bind:required="true"
                                    v-bind:disabledDates="disabledDates"
                                    v-model="preferredDate">
                            <span slot="beforeDateInput"
                                  class="input-group-prepend">
                                <span class="input-group-text">
                                    <font-awesome-icon icon="calendar"></font-awesome-icon>
                                </span>
                            </span>
                        </datepicker>
                    </b-form-group>

                    <b-form-group class="col-md-6"
                                  label="預約時間"
                                  label-for="preferredSlot"
                                  description="* 我們會在收到閣下預約後致電確認">
                        <b-form-select class="rounded-0"
                                       id="preferredSlot"
                                       required
                                       v-model="preferredSlot">
                            <option v-bind:value="null">請選擇閣下喜歡的時段</option>
                            <option value="10:00-11:00">10:00-11:00</option>
                            <option value="11:00-12:00">11:00-12:00</option>
                            <option value="12:00-13:00">12:00-13:00</option>
                            <option value="14:00-15:00">14:00-15:00</option>
                            <option value="15:00-16:00">15:00-16:00</option>
                            <option value="16:00-17:00">16:00-17:00</option>
                            <option value="17:00-18:00">17:00-18:00</option>
                            <option value="18:00-19:00">18:00-19:00</option>
                            <option value="19:00-20:00">19:00-20:00</option>
                            <option value="20:00-21:00">20:00-21:00</option>
                        </b-form-select>
                    </b-form-group>
                </div>

                <div class="row mt-2 mb-4"
                     v-if="withOtherTreatments"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <b-form-group class="col-md-6"
                                  label="請選擇療程:"
                                  label-for="treatment">
                        <b-form-select class="rounded-0"
                                       id="treatment"
                                       required
                                       v-model="interestedTreatment">
                            <option v-for="{ treatmentId, title } in campaigns.filter(({ hidden }) => !hidden)"
                                    v-bind:key="treatmentId"
                                    v-bind:value="treatmentId">
                                {{ title["zh-hk"] }}
                            </option>
                        </b-form-select>
                    </b-form-group>

                    <div class="col-md-6"
                         v-if="withExtraBookingDiscounts">
                        <div class="card">
                            <div class="card-body py-3">
                                <h5 class="font-weight-bolder card-title">
                                    額外加購優惠
                                </h5>

                                <div class="form-check"
                                     v-for="({ text, value }, index) in extraBookingDiscounts"
                                     v-bind:key="text">
                                    <input class="form-check-input"
                                           type="radio"
                                           v-bind:id="`extra${ index }`"
                                           v-bind:value="value"
                                           v-model="extra">
                                    <label class="form-check-label"
                                           v-bind:for="`extra${ index }`"
                                           v-html="text">
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input"
                                           type="radio"
                                           id="noExtra"
                                           v-bind:value="null"
                                           v-model="extra">
                                    <label class="form-check-label"
                                           for="noExtra">
                                        不用加購
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="text-center"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <button class="btn btn-outline-dark rounded-0 px-4"
                            type="submit"
                            v-bind:class="{ 'btn-loading': isLoading }">
                        立即傳送
                    </button>
                </div>
            </b-form>
        </div>

        <modal-pay-as-you-wish v-if="showDepositDiscountNotice"
                               v-model="isModalPayAsYouWishOpen">
            <slot name="discount"></slot>
        </modal-pay-as-you-wish>

        <b-modal hide-footer
                 centered
                 title="感謝您的預約！"
                 v-model="isModalSubmittedOpen">
            <!-- 我們將以手機SMS及電郵方式作預約排程確認，並於24小時內以Whatsapp或電話與閣下聯絡 -->
            我們將於24小時內以Whatsapp或電話與閣下聯絡確認預約。
        </b-modal>
    </section>
</template>

<script>
import createCampaignEnquiry from "../../api/mall/createCampaignEnquiry";
import isEmail from "validator/lib/isEmail";

import {
    BModal,
    BFormCheckbox,
    BFormSelect,
    BFormRadioGroup,
    BFormRadio,
    BInputGroup,
    BInputGroupText,
    BFormInput,
    BFormGroup,
    BForm,
} from "bootstrap-vue";

import Datepicker from "vuejs-datepicker";
import ModalPayAsYouWish from "./ModalPayAsYouWish";

export default {
    name: "Enquiry",
    components: {
        ModalPayAsYouWish,
        Datepicker,
        BModal,
        BFormCheckbox,
        BFormSelect,
        BFormRadioGroup,
        BFormRadio,
        BInputGroup,
        BInputGroupText,
        BFormInput,
        BFormGroup,
        BForm,
    },
    props: {
        "treatmentId": {
            type: String,
            required: true
        },

        "currency": {
            type: String,
            default: "hkd",
        },

        "amount": {
            type: String,
        },

        "withOtherTreatments": {
            type: Boolean,
            default: true,
        },

        "withBookingOption": {
            type: Boolean,
            default: true,
        },

        "withExtraBookingDiscounts": {
            type: Boolean,
            default: false,
        },

        "extraBookingDiscounts": {
            type: Array,
        },

        "duplicateToGSheet": {
            type: Boolean,
            default: false,
        },

        "showDepositDiscountNotice": {
            type: Boolean,
            default: false,
        },

        "gSheetId": {
            type: [Object, String],
        },

        "gSheetIdKey": {
            type: String,
        }
    },
    data () {
        return {
            isLoading: false,

            isNameValid: null,
            isEmailValid: null,
            isPhoneValid: null,

            isModalPayAsYouWishOpen: false,
            isModalSubmittedOpen: false,

            name: "",
            gender: "female",
            email: "",
            phone: "",

            interestedTreatment: "",
            extra: null,

            bookingAFreeSession: true,
            branch: null,
            preferredDate: null,
            preferredSlot: null,
        }
    },
    computed: {
        salons () {
            return this.$store.state.mall.shop.branches
                .filter(
                    branch => branch.type === "salon"
                );
        },

        campaigns () {
            return this.$store.state.mall.campaigns;
        },

        treatment () {
            if (this.treatmentId) {
                let target = this.$store.state.mall.salon.treatments.find(
                    treatment => treatment._id === this.interestedTreatment
                );
                if (target) {
                    return target;
                } else {
                    return {};
                }
            }
        },

        disabledDates () {
            const defaultDates = [
                new Date(2020, 7, 12),
                new Date(2020, 7, 13),
                new Date(2020, 7, 14),
                new Date(2020, 7, 15),
                new Date(2020, 7, 16),
                new Date(2020, 7, 17),
                new Date(2020, 7, 18),
            ];

            const dates = {
                to: new Date(),
                dates: [...defaultDates],
                ranges: [],
            };

            if (this.branch) {
                if (this.branch._id === "5eeac3e203f2a1090658ddf5") {
                    dates.dates = [];
                }

                const branchRange = {};
                if (this.branch.openingDate) {
                    branchRange.to = (new Date(this.branch.openingDate).getTime() > new Date().getTime()) ?
                        new Date(this.branch.openingDate) :
                        dates.to;
                }

                if (this.branch.closingDate) {
                    branchRange.from = new Date(this.branch.closingDate);
                }

                if (Object.keys(branchRange).length > 0) {
                    dates.ranges.push(branchRange);
                }
            }
            return dates;
        },

        allowSubmission () {
            if (this.bookingAFreeSession) {
                return this.name &&
                    this.isNameValid &&
                    this.email &&
                    this.isEmailValid &&
                    this.phone &&
                    this.isPhoneValid &&
                    this.branch &&
                    this.preferredDate &&
                    this.preferredSlot;
            }
            return this.name &&
                this.isNameValid &&
                this.email &&
                this.isEmailValid &&
                this.phone &&
                this.isPhoneValid;
        },

        validFeedback () {
            return "";
        },

        invalidNameFeedback () {
            if (!this.name) {
                return "請輸入你的名字";
            }

            return "";
        },

        invalidEmailFeedback () {
            if (!this.email) {
                return "請輸入你的電郵地址";
            }

            if (!isEmail(this.email)) {
                return "電子郵件地址無效";
            }

            return "";
        },

        invalidPhoneFeedback () {
            const phonePattern = new RegExp("[0-9]{8}", "g");

            if (!this.phone) {
                return "請輸入你的電話號碼";
            }

            if (
                !phonePattern.test(this.phone) ||
                this.phone.length > 0 &&
                this.phone.length < 0
            ) {
                return "香港電話號碼由八個位的數字組成";
            }

            return "";
        },

        invalidBranchFeedback () {
            if (!this.branch) {
                return "請選擇預約分店";
            }
            return "";
        },
    },
    methods: {
        reset () {
            this.isNameValid = null;
            this.isEmailValid = null;
            this.isPhoneValid = null;
            this.isBranchValid = null;

            this.name = "";
            this.gender = "female";
            this.email = "";
            this.phone = "";

            this.bookingAFreeSession = true;
            this.branch = null;
            this.preferredDate = null;
            this.preferredSlot = null;

            this.extra = null;
        },

        validateName () {
            this.isNameValid = !!this.name;
        },

        validateEmail () {
            this.isEmailValid = isEmail(this.email);
        },

        validatePhone () {
            const phonePattern = new RegExp("[0-9]{8}", "g");
            this.isPhoneValid = !!this.phone &&
                this.phone.length === 8 &&
                phonePattern.test(this.phone);
        },

        validateBranch (branch) {
            this.isBranchValid = !!branch;
        },

        checkSelectedDate (branch) {
            if (this.preferredDate) {
                if (branch.openingDate) {
                    if (this.preferredDate.getTime() < new Date(branch.openingDate).getTime()) {
                        this.preferredDate = null
                    }
                }
                if (branch.closingDate) {
                    if (this.preferredDate.getTime() > new Date(branch.closingDate).getTime()) {
                        this.preferredDate = null
                    }
                }
            }
        },

        onBranchChange (val) {
            this.preferredDate = null;
            this.validateBranch(val);
            this.checkSelectedDate(val);
        },

        async submit () {
            if (!this.allowSubmission) {
                return;
            }

            const {
                name, gender, email, phone, extra,
            } = this;

            const enquiry = {
                name, gender, email, phone, extra,
            };

            if (this.bookingAFreeSession) {
                enquiry.branch = this.branch._id;
                enquiry.preferredDate = this.preferredDate;
                enquiry.preferredSlot = this.preferredSlot;
            }

            enquiry.ua = window.navigator?.userAgent;

            enquiry.utm_source = this.$route.query?.utm_source;
            enquiry.utm_medium = this.$route.query?.utm_medium;
            enquiry.utm_campaign = this.$route.query?.utm_campaign;
            enquiry.utm_term = this.$route.query?.utm_term;
            enquiry.utm_content = this.$route.query?.utm_content;

            let dataForGSheet;
            if (this.duplicateToGSheet === true) {
                dataForGSheet = {
                    "name": this.name,
                    "gender": this.gender,
                    "email": this.email,
                    "phone": this.phone,
                    "treatment": this.$sanitize(this.treatment.title["en"]),
                    "extra": this.extra,
                    "from": `${ enquiry.utm_source }${ enquiry.utm_campaign ? `:${ enquiry.utm_campaign }` : "" }`,
                };

                if (this.bookingAFreeSession) {
                    dataForGSheet.branch = this.branch.address["zh-hk"];
                    dataForGSheet.preferredDate = this.preferredDate;
                    dataForGSheet.preferredSlot = this.preferredSlot;
                }
            }

            this.isLoading = true;
            try {
                await createCampaignEnquiry(
                    this.interestedTreatment,
                    enquiry,
                    this.gSheetId,
                    this.gSheetIdKey,
                    dataForGSheet
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }

            this.$ma.trackEvent(
                {
                    "category": "campaign-enquiry",
                    "action": "form-submission",
                    "label": this.$sanitize(this.treatment.title["en"]),
                    "value": Date.now()
                },
                ["facebook"]
            );

            this.$ma.trackEvent(
                {
                    "fb_event": "Schedule",
                    "ids": [this.treatment._id],
                    "label": this.$sanitize(this.treatment.title["en"]),
                    "type": "product",
                    "value": Date.now()
                },
                ["ga"]
            );

            this.isLoading = false;
            this.isModalSubmittedOpen = true;
            this.isModalPayAsYouWishOpen = true;
            this.reset();
        }
    },
    mounted () {
        this.interestedTreatment = this.treatmentId;
    },
}
</script>

<style lang="scss" scoped>
.enquiry {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/promotion_bg.png");

    .btn-outline-dark {
        background-color: rgba(255, 255, 255, 1);
    }

    .container {
        .btn-group#gender {
            button {
                &.active {
                    color: rgba(255, 255, 255, 1);
                    background-color: rgba(126, 220, 240, 1);
                    border-color: rgba(126, 220, 240, 1);
                }
            }
        }
    }
}
</style>
