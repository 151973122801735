import { apiUrl, apiVersion, } from "../../config";

export async function createCampaignEnquiry (
    treatmentId,
    enquiry,
    gSheetId,
    gSheetIdKey,
    gSheetData
) {
    const response = await fetch(
        `${apiUrl}/${apiVersion}/mall/campaign/${treatmentId}/enquiry`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "body": JSON.stringify(enquiry),
        }
    );

    if (response.ok) {
        const message = await response.json();
        if (message) {
            if (gSheetId && gSheetData) {
                if (typeof gSheetId === "object") {
                    if (gSheetIdKey) {
                        fetch(`https://script.google.com/macros/s/${gSheetId[gSheetData[gSheetIdKey]]}/exec?from=${gSheetData.from}&name=${gSheetData.name}&gender=${gSheetData.gender}&tel=${gSheetData.phone}&email=${gSheetData.email}&treatment=${gSheetData.treatment}&branch=${gSheetData.branch}&preferredDate=${gSheetData.preferredDate.toDateString()}&preferredSlot=${gSheetData.preferredSlot}&extra=${gSheetData.extra}`);
                    }
                } else {
                    fetch(`https://script.google.com/macros/s/${gSheetId}/exec?from=${gSheetData.from}&name=${gSheetData.name}&gender=${gSheetData.gender}&tel=${gSheetData.phone}&email=${gSheetData.email}&treatment=${gSheetData.treatment}&branch=${gSheetData.branch}&preferredDate=${gSheetData.preferredDate.toDateString()}&preferredSlot=${gSheetData.preferredSlot}&extra=${gSheetData.extra}`);
                }
            }
            return enquiry;
        }
        throw "MALL CREATE CAMPAIGN ENQUIRY error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "internal server error";
}

export default createCampaignEnquiry;
